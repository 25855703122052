import * as moment from 'moment';

import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'any'
})
export class LocalstorageService {
	public salvarLocal(chave: string, valor: any) {
		localStorage.setItem(chave, JSON.stringify(valor));
	}

	public apagarLocal(chave: string) {
		localStorage.setItem(chave, '');
	}

	public obterLocal(chave: string): any {
		if (localStorage.getItem(chave) !== undefined) {
			return localStorage.getItem(chave);
		}
		return undefined;
	}

	public autoLimpeza(chave: string, chaveExpiracao: string) {
		const diasExpiracao = 30;
		const ultimaAtualizacao = localStorage.getItem(chaveExpiracao);
		if (ultimaAtualizacao != null) {
			const hoje = moment().format('YYYY-MM-DD');
			if (this.diferencaEmDias(hoje, ultimaAtualizacao) > diasExpiracao) {
				this.apagarLocal(chave);
			}
		}
	}

	private diferencaEmDias(data1: string, data2: string): number {
		const d1 = new Date(data1);
		const d2 = new Date(data2);
		return moment(d1).diff(moment(d2), 'days');
	}
}
