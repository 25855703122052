import { AuthGuard, AuthInterceptor, RoleGuard, TransactionGuard } from '@sigepe/ngx-sigepe-seguranca';

import { ConfigEnvironment } from './environment.base';

const HOST_GRATIFICACAO = 'https://homolog-sigepe-api.estaleiro.serpro.gov.br/gratificacao';
const HOST_EORG = 'https://homolog-sigepe-api.estaleiro.serpro.gov.br/eorg';
const HOST_TABELAS_AUXILIARES = 'https://homolog-sigepe-api.estaleiro.serpro.gov.br/tabelas-auxiliares';
const SECURITY_HOSTS = ['https://homolog-sigepe-api.estaleiro.serpro.gov.br'];

//Autoriza
const HOST_LOGIN_UNICO = 'https://sso.staging.acesso.gov.br';
const HOST_JSKS = 'http://localhost:8080';
const HOST_API_SIGEPE = 'https://homolog-sigepe-api.estaleiro.serpro.gov.br/seguranca';
const CLIENT_ID = 'staging.sigepe.planejamento.gov.br';
const COOKIE_DOMAIN = '.sigepe.gov.br';

const config = new ConfigEnvironment(
	HOST_GRATIFICACAO,
	HOST_EORG,
	HOST_TABELAS_AUXILIARES,
	SECURITY_HOSTS,
	HOST_LOGIN_UNICO,
	HOST_JSKS,
	HOST_API_SIGEPE,
	CLIENT_ID,
	COOKIE_DOMAIN
);

export const environment = {
	urlPermitidas: [HOST_GRATIFICACAO, HOST_EORG, HOST_TABELAS_AUXILIARES],
	PROVIDER_AUTH: AuthInterceptor,
	AUTH: [AuthGuard],
	GUARDS: [AuthGuard, RoleGuard],
	TRANSACTION: [AuthGuard, TransactionGuard],
	GUARDS_AND_TRANSACTION: [AuthGuard, RoleGuard, TransactionGuard],
	production: false,
	footerUrl: 'https://homolog-sigepe-api.estaleiro.serpro.gov.br/template/rodapeSigepe/text',
	menuUrl: 'https://homolog-sigepe-api.estaleiro.serpro.gov.br/menu-sigepe',
	mensageriaUrl: 'https://desenv.sigepe.csd.serpro/sigepe-ca-mensageiria-web/private/leitor.jsf',
	manualUrl: 'https://hom.bgp.sigepe.gov.br/sigepe-bgp-web-intranet/pages/manual/consultar.jsf',
	sistemaOrigem: 'GRATIFICACAO',
	menuAddFromRouter: true,
	menuIgnoreRouterGuard: false,
	avaliacaoFuncionalidadeConfiguration: {
		urlApi: 'https://homolog-sigepe-api.estaleiro.serpro.gov.br/pesquisa-avaliacao/avaliacao-servico/v1/'
	},
	...config.getEnvironment()
};
