export enum TransacoesEnum {
	DASHBOARD = 'GRA_DASHBOARD',
	MANTER_EVENTO_GECC = 'GRA_MANTER_EVENTO_GECC',
	DETALHAR_EVENTO_GECC = 'GRA_DETALHAR_EVENTO_GECC',
	PESQUISAR_EVENTO_GECC = 'GRA_PESQUISAR_EVENTO_GECC',
	CONFIRMAR_PAGAMENTO_GECC = 'GRA_CONFIRMAR_PAGAMENTO',
	PESQUISAR_PAGAMENTOS_GECC = 'GRA_PESQUISAR_PAGAMENTOS',
	CONSULTA_SALDO_HORAS_GECC = 'GRA_CONSULTA_SALDO_HORAS_GECC',
	GERENCIAR_AUTORIZACAO_EXCEDENTE_HORAS_GECC = 'GRA_GERENCIAR_AUTORIZACAO_EXCEDENTE_HORAS_GECC'
}
