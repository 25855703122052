export class Navegacao {
	url?: string;
	filtro?: any;
	pagina?: number;
	tamanhoPagina?: number;
	tab?: number;

	constructor(url?: string, filtro?: any, tabela?: any, pagina?: number, tamanhoPagina?: number, tab?: number) {
		this.url = url;
		this.filtro = filtro;
		this.pagina = pagina;
		this.tamanhoPagina = tamanhoPagina;
		this.tab = tab;
	}

	reset() {
		this.url = undefined;
		this.filtro = undefined;
		this.pagina = undefined;
		this.tamanhoPagina = undefined;
		this.tab = undefined;
	}
}
