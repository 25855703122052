import { BrowserModule, Title } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorsInterceptor } from './shared/interceptors/errors.interceptor';
import { MessageService } from 'primeng/api';
import { SigepeMainLayoutModule } from '@sigepe/sigepe-template';
import { SigepeSegurancaModule } from '@sigepe/ngx-sigepe-seguranca';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';

export function httpTranslateLoader(http: HttpClient): any {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		SigepeSegurancaModule.forRoot(environment.SEGURANCA_CONFIG),
		SigepeMainLayoutModule.forRoot(environment),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpTranslateLoader,
				deps: [HttpClient]
			}
		})
	],
	providers: [
		Title,
		MessageService,
		{ provide: HTTP_INTERCEPTORS, useClass: environment.PROVIDER_AUTH, multi: true },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorsInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
	exports: []
})
export class AppModule {}
