import { Injectable } from '@angular/core';
import { LocalstorageService } from './localstorage.service';
import { Navegacao } from '../models/navegacao';

@Injectable({
	providedIn: 'any'
})
export class NavegacaoService {
	constructor(private localStorage: LocalstorageService) {}

	public gravarNavegacao(url: string, filtro?: any, tabela?: any, pagina?: number, tamanhoPagina?: number, tab?: number) {
		const navegacao = new Navegacao(url, filtro, tabela, pagina, tamanhoPagina, tab);
		this.localStorage.salvarLocal('navegacao', navegacao);
	}

	public obterDadosNavegacao(): Navegacao | undefined {
		const localNavegacao = this.localStorage.obterLocal('navegacao');
		if (localNavegacao) {
			return JSON.parse(this.localStorage.obterLocal('navegacao'));
		}
		return undefined;
	}

	public limparNavegacao() {
		this.localStorage.apagarLocal('navegacao');
	}
}
