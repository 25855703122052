import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { Perfis } from './shared/enums/perfis.enum';
import { SigepeMainLayoutModule } from '@sigepe/sigepe-template';
import { SigepeSegurancaModule } from '@sigepe/ngx-sigepe-seguranca';
import { TransacoesEnum } from './shared/enums/transacoes.enum';
import { environment } from 'src/environments/environment';

const routerOptions: ExtraOptions = {
	//TODO: Futuramente fazer pre-carregamento customizado: https://blog.bitsrc.io/preloading-strategies-boost-up-angular-app-loading-time-ffb19da63155
	preloadingStrategy: PreloadAllModules,
	onSameUrlNavigation: 'reload',
	useHash: false
};

const routes: Routes = [
	{
		path: '',
		redirectTo: 'dashboard',
		pathMatch: 'full'
	},
	{
		path: 'dashboard',
		loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
		data: {
			title: 'Dashboard',
			breadcrumb: 'Dashboard',
			showInMenu: true,
			transactions: TransacoesEnum.DASHBOARD,
			roles: [Perfis.GESTOR_PESSOAS, Perfis.GESTOR_CENTRAL]
		},
		canLoad: environment.GUARDS_AND_TRANSACTION,
		canActivate: environment.GUARDS_AND_TRANSACTION
	},
	{
		path: 'gecc/pesquisar-eventos',
		loadChildren: () =>
			import('./gecc/eventos/pesquisar-eventos-gecc/pesquisar-eventos-gecc.module').then(m => m.PesquisarEventosGeccModule),
		data: {
			title: 'Pesquisar eventos GECC',
			breadcrumb: 'Pesquisar eventos GECC',
			showInMenu: true,
			transactions: TransacoesEnum.PESQUISAR_EVENTO_GECC,
			roles: [Perfis.GESTOR_PESSOAS, Perfis.GESTOR_CENTRAL]
		},
		canLoad: environment.GUARDS_AND_TRANSACTION,
		canActivate: environment.GUARDS_AND_TRANSACTION
	},
	{
		path: 'gecc/eventos/manter',
		loadChildren: () => import('./gecc/eventos/manter-evento-gecc/manter-evento-gecc.module').then(m => m.ManterEventoGeccModule),
		data: {
			title: 'Cadastrar evento GECC',
			breadcrumb: 'Cadastrar evento GECC',
			showInMenu: true,
			transactions: TransacoesEnum.MANTER_EVENTO_GECC,
			roles: [Perfis.GESTOR_PESSOAS]
		},
		canLoad: environment.GUARDS_AND_TRANSACTION,
		canActivate: environment.GUARDS_AND_TRANSACTION
	},
	{
		path: 'gecc/consultar-pagamentos',
		loadChildren: () =>
			import('./gecc/pagamentos/consultar-pagamentos/consultar-pagamentos.module').then(m => m.ConsultarPagamentosModule),
		data: {
			title: 'Consultar pagamentos GECC',
			breadcrumb: 'Consultar pagamentos GECC',
			showInMenu: true,
			transactions: TransacoesEnum.PESQUISAR_PAGAMENTOS_GECC,
			roles: [Perfis.GESTOR_PESSOAS, Perfis.GESTOR_PAGAMENTOS, Perfis.GESTOR_CENTRAL]
		},
		canLoad: environment.GUARDS_AND_TRANSACTION,
		canActivate: environment.GUARDS_AND_TRANSACTION
	},
	{
		path: 'gecc/consultar-saldo-horas',
		loadChildren: () =>
			import('./gecc/horas/consultar-saldo-horas/consultar-saldo-horas.module').then(m => m.ConsultarSaldoHorasModule),
		data: {
			title: 'Saldo de horas GECC do Servidor',
			breadcrumb: 'Saldo de horas GECC do Servidor',
			showInMenu: true,
			transactions: TransacoesEnum.CONSULTA_SALDO_HORAS_GECC,
			roles: [Perfis.GESTOR_PESSOAS]
		},
		canLoad: environment.GUARDS_AND_TRANSACTION,
		canActivate: environment.GUARDS_AND_TRANSACTION
	},
	{
		path: 'gecc/gerenciar-excedente-horas',
		loadChildren: () =>
			import('./gecc/horas/gerenciar-excedente-horas/gerenciar-excedente-horas.module').then(m => m.GerenciarExcedenteHorasModule),
		data: {
			title: 'Gerenciar excedente de horas GECC',
			breadcrumb: 'Gerenciar excedente de horas GECC',
			showInMenu: true,
			transactions: TransacoesEnum.GERENCIAR_AUTORIZACAO_EXCEDENTE_HORAS_GECC,
			roles: [Perfis.GESTOR_PESSOAS]
		},
		canLoad: environment.GUARDS_AND_TRANSACTION,
		canActivate: environment.GUARDS_AND_TRANSACTION
	},
	{
		path: 'gecc/eventos/:idEvento',
		loadChildren: () => import('./gecc/eventos/detalhar-evento-gecc/detalhar-evento-gecc.module').then(m => m.DetalharEventoGeccModule),
		data: {
			title: 'Evento GECC',
			breadcrumb: 'Evento GECC',
			showInMenu: false,
			transactions: TransacoesEnum.DETALHAR_EVENTO_GECC,
			roles: [Perfis.GESTOR_PESSOAS, Perfis.GESTOR_CENTRAL]
		},
		canLoad: environment.GUARDS_AND_TRANSACTION,
		canActivate: environment.GUARDS_AND_TRANSACTION
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, routerOptions), SigepeMainLayoutModule, SigepeSegurancaModule],
	exports: [RouterModule]
})
export class AppRoutingModule {}
